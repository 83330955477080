module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"pinwheel-web","short_name":"starter","start_url":"/","background_color":"#ffffff","theme_color":"#16bdca","display":"minimal-ui","icon":"src/images/tailwind-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"noHash":true,"noQueryString":true,"noTrailingSlash":true,"siteUrl":"https://www.pinwheel.fm"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
